import React from "react";
import {css} from "@emotion/core";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import { Link } from 'gatsby';
import typography from "../../styles/typography";
import Container from "../Context/Container";
import LogoSVG from "../SVGs/Logo";
import MenuSVG from "../SVGs/MenuSvg";

const LogoStyling = (props) => css`
    display: block;
    padding: 17px 16px;
    flex-shrink: 1;
    flex-basis: 0;
    width: auto;
    float: left;
    clear: left;

    svg{
        height: 34px;
        width: 129px;
        fill: ${props.theme === "dark" ? color("dark") : color("white")};
        transition: fill .2s;
    }

    @media screen and (min-width: 768px) {
        padding: 22px;

        svg{
            height: 55px;
            width: 209px;
        }
    }

    @media screen and (min-width: 1200px) {
        border-right: ${props.hideNav ? "none" : `4px solid ${props.theme === "dark" ? color("border") : color("white")}`};
        padding: 30px;
    }

    @media screen and (min-width: 1800px) {
        padding: 25px;

        svg{
            height: 61px;
            width: 231px;
        }
    }
`;

const Navigation = styled.div`
    padding: 0;
    flex-grow: 1;
    text-align: right;

    ul{
        height: 100%;
    }

    li{
        display: inline-block;
        height: 100%;
    }
`;

const MenuButton = styled.div`
    display: inline-block;
    cursor: hand;
    cursor: pointer;
    ${typography.menuLink};
    color: ${props => props.theme === "dark" ? color("dark") : color("white")};
    transition: color .2s, border-color .2s;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    text-decoration: none;
    font-weight: 600;
    text-transform: none;
    border-left: 3px solid ${props => props.theme === "dark" ? color("border") : color("white")};

    &:hover{
        text-decoration: underline;
    }

    span{
        display: none;
        margin-right: 16px;
    }

    svg{
        fill: ${props => props.theme === "dark" ? color("dark") : color("white")};
        transition: fill .2s;
    }

    @media screen and (min-width: 768px) {
        padding: 0 24px;
    }

    @media screen and (min-width: 1200px) {
        border-left: none;

        span{
            display: inline-block;
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 0 32px;
    }
`;

const MenuBar = ({theme, handleMenuOpen, hideNav}) => {


    return (
        <Container type="header" noFlex={hideNav}>
            {hideNav !==true ? (
                <Link to="/" css={LogoStyling({theme: theme, hideNav: hideNav})}>
                    <LogoSVG />
                </Link>
            ) : (
                <div css={LogoStyling({theme: theme, hideNav: hideNav})}>
                    <LogoSVG />
                </div>
            )}
            {hideNav !== true && (
                <Navigation theme={theme}>
                    <ul>
                        <li>
                            <MenuButton onClick={handleMenuOpen} theme={theme}><span>Menu</span><MenuSVG /></MenuButton>
                        </li>
                    </ul>
                </Navigation>
            )}
        </Container>
    );
};

export default MenuBar;
