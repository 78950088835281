import React from "react";
import {css} from "@emotion/core";
import styled from "@emotion/styled";
import LogoSVG from "../SVGs/Logo";
import color, { hexToRGB } from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import Newsletter from "../Context/Newsletter";
import { graphql, Link, useStaticQuery } from "gatsby";

const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`;
const NewsletterWrapper = styled.div`
    @media screen and (min-width: 768px) {
        padding-top: 72px;
        padding-bottom: 81px;
    }
    @media screen and (min-width: 1200px) {
        flex-grow: 1;
    }
`;
const MenuWrapper = styled.div`
    border-top: ${props => props.hideNav ? "none" : `1px solid ${color("extra light")}`};
    padding-top: ${props => props.hideNav ? "0" : "40px"};
    margin-top: ${props => props.hideNav ? "0" : "40px"};
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        margin-top: 0;
        border-top: none;
        padding-top: 72px;
        padding-bottom: 81px;
        border-left: ${props => props.hideNav ? "none" : `1px solid ${color("extra light")}`};
        padding-left: ${props => props.hideNav ? "0" : "57px"};
        margin-left: ${props => props.hideNav ? "0" : "89px"};
        min-width: 224px;
        ${props => props.hideNav ? css`
            flex-direction: row-reverse;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
        ` : null}
    }

    @media screen and (min-width: 1200px) {
        min-width: 242px;
    }

    @media screen and (min-width: 1800px) {
        min-width: 290px;
    }
`;
const LogoWrapper = styled.div`
    width: 160px;
    height: 42px;
    margin: 0 0 ${props => props.noMargin ? "0" : "12px"};

    svg{
        fill: ${color("white")};
        width: 100%;
        height: 100%;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: ${props => props.noMargin ? "0" : "36px"};
    }
`;
const Navigation = styled.ul`
    ${typography.link};

    li{
        a{
            color: ${color("white")};
            font-weight: normal;
            padding: 12px 0;
            display: inline-block;

            @media screen and (min-width: 1200px) {
                font-weight: bold;
                text-decoration: none;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
`;
const Copyright = styled.p`
    ${typography.meta};
    color: ${hexToRGB(color("white"), 0.6)};
    margin-top: 30px;
    flex-grow: ${props => props.noMargin ? "0" : "1"};
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media screen and (min-width: 768px) {
        margin-top: ${props => props.noMargin ? "0" : "30px"};
    }
`;

const Footer = ({theme, hideNav, noFlex}) => {

    const { prismicOptions } = useStaticQuery(
        graphql`
            {
                prismicOptions {
                    data {
                        footer_menu {
                            name
                            link {
                                url
                            }
                        }
                    }
                }
            }
        `
    )
    return (
        <Section paddingS="56px 0 72px" paddingM="0" paddingL="0" paddingXL="0" paddingXXL="0" bg="dark" fg="white" theme={theme} borderBottom={true} noFlex={noFlex}>
            <Container>
                <FooterWrapper>
                    {hideNav !== true && (
                        <NewsletterWrapper>
                            <Newsletter />
                        </NewsletterWrapper>
                    )}
                    <MenuWrapper hideNav={hideNav}>
                        <LogoWrapper noMargin={hideNav}>
                            <LogoSVG />
                        </LogoWrapper>
                        {hideNav !== true && (
                            <Navigation>
                                {prismicOptions.data.footer_menu.map((item, i) => {
                                    return item.link.url ? (
                                        <li key={i}><Link to={item.link.url}>{item.name}</Link></li>
                                    ) : null;
                                })}
                            </Navigation>
                        )}
                        <Copyright noMargin={hideNav}>
                            Copyright L’Atelier {new Date().getFullYear()}
                        </Copyright>
                    </MenuWrapper>
                </FooterWrapper>
            </Container>
        </Section>
    );
};

export default Footer;
