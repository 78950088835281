import { useEffect, useState } from "react";

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== 'undefined'

/**
 * Prismic preview hook
 * @param {object} staticData Data object from Gatsby page
 */

export default function usePreviewData(staticData) {
    const [data, setData] = useState(staticData);

    useEffect( () => {
        async function mergeData() {
            if (IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__) {
                const { mergePrismicPreviewData } = await import(
                    /* webpackChunkName: "gatsby-source-prismic" */ 'gatsby-source-prismic'
                    );

                setData(
                    mergePrismicPreviewData({
                        staticData,
                        previewData: window.__PRISMIC_PREVIEW_DATA__
                    })
                );
            }
        }
        mergeData();

    }, [staticData]);
    return data;
}