import React from "react";

const MenuSVG = () => {
    return (
        <svg width="46" height="26" viewBox="0 0 46 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="46" height="4"/>
            <rect y="11" width="46" height="4"/>
            <rect y="22" width="46" height="4"/>
        </svg>
    );
};

export default MenuSVG;