import React from "react";

const CloseSVG = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="45.2024" height="3.93065" transform="matrix(0.709178 0.705029 -0.709178 0.705029 2.94336 0.359863)"/>
            <rect width="45.2024" height="3.93064" transform="matrix(0.709497 -0.704709 0.709497 0.704709 0 31.8545)"/>
        </svg>
    );
};

export default CloseSVG;