import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

const Wrapper = styled.div``;

const ContainerEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 1000px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 1120px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 1430px;
    }

    ${props => props.extraBreakpoint ? css`
        @media screen and (min-width: 2200px) {
            max-width: 1912px;
        }
    ` : null}
`;

// hero
const ContainerHeroEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 100;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 1000px;
        align-items: flex-end;
    }

    @media screen and (min-width: 1200px) {
        max-width: 1120px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 1430px;
    }

    ${props => props.extraBreakpoint ? css`
        @media screen and (min-width: 2200px) {
            max-width: 1912px;
        }
    ` : null}
`;

// header ---- special
const ContainerHeaderEl = styled.div`
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    font-family: Faktum, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${props => props.noFlex !== true && css`
        display: flex;
        flex-direction: row;
    `}

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }
`;

// full
const ContainerFullEl = styled.div`
    padding: 0 20px;
    width: 100%;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        padding: 0;
    }
`;

// full without padding
const ContainerFullNoPaddingEl = styled.div`
    padding: 0;
    width: 100%;

    ${Wrapper}{
        width: 100%;
    }
`;

// contact
const ContainerContactEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 1000px;
    }

    @media screen and (min-width: 1200px) {
        padding: 0;
        max-width: 510px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 660px
    }
`;

// projects - wide
const ContainerWideEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 1000px;
        padding: 0 20px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 1274px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 1430px;
    }
`;

// CTA - narrow
const ContainerNarrowEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 503px;
        padding: 0 20px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 721px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 928px;
    }
`;

// Body Insights article - article-narrow
const ContainerArticleNarrowEl = styled.div`
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    ${Wrapper}{
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        max-width: 1000px;
        padding: 0 105px;
    }

    @media screen and (min-width: 1200px) {
        max-width: 719px;
        padding: 0;
    }

    @media screen and (min-width: 1800px) {
        max-width: 928px;
    }
`;

const Container = ({children, type, extraBreakpoint, noFlex, className, inline}) => {
    return (
        <>
            {type === "header" ? 
            (
                <ContainerHeaderEl className={className} noFlex={noFlex} as={inline ? "span" : "div"}>
                    {children}
                </ContainerHeaderEl>
            ) : type === "hero" ?
            (
                <ContainerHeroEl extraBreakpoint={extraBreakpoint} className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerHeroEl>
            ) : type === "wide" ?
            (
                <ContainerWideEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerWideEl>
            ) : type === "narrow" ?
            (
                <ContainerNarrowEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerNarrowEl>
            ) : type === "article-narrow" ?
            (
                <ContainerArticleNarrowEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerArticleNarrowEl>
            ) : type === "full" ?
            (
                <ContainerFullEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerFullEl>
            ) : type === "full-no-padding" ?
            (
                <ContainerFullNoPaddingEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerFullNoPaddingEl>
            ) : type === "contact" ?
            (
                <ContainerContactEl className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerContactEl>
            ) : (
                <ContainerEl extraBreakpoint={extraBreakpoint} className={className} as={inline ? "span" : "div"}>
                    <Wrapper as={inline ? "span" : "div"}>
                        {children}
                    </Wrapper>
                </ContainerEl>
            )}
        </>
    );
};

export default Container;
