import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import color from "../../styles/colors";

const SectionEl = styled.div`
    width: 100%;
    padding: ${props => props.paddingS ? props.paddingS : "64px 0"};
    background-color: ${props => color(props.bg)};
    color: ${props => color(props.fg)};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: ${props => props.center ? "center" : "left"};
    ${props => props.bgImage ? css`
        background-image: url(${props.bgImage});
        background-size: cover;
        background-position: center center;
    ` : null}
    min-height: ${props => props.minHeight ? props.minHeight : "0"};
    max-height: ${props => props.maxHeight ? props.maxHeight : "none"};
    ${props => props.minHeightS ? `min-height: ${props.minHeightS};` : null}
    flex-grow: ${props => props.flexGrow ? props.flexGrow : "0"};

    ${props => props.noPaddingTop && css`
        padding-top: 0;
    `}

    ${props => props.noFlex !== true && css`
        display: flex;
        flex-direction: row;
        justify-content: center;
    `}

    ${props => props.hr ? css`
        border-bottom: 1px solid ${color(props.hr)};
    ` : null}

    ${props => props.border ? css`
        border-left: 3px solid ${props.theme === "dark" ? color("border") : color("white")};
        border-right: 3px solid ${props.theme === "dark" ? color("border") : color("white")};
        box-sizing: border-box;
        position: relative;
    ` : null};

    ${props => props.borderTop ? css`
        border-top: 3px solid ${props.theme === "dark" ? color("border") : color("white")};
    ` : null};

    ${props => props.borderBottom ? css`
        border-bottom: 3px solid ${props.theme === "dark" ? color("border") : color("white")};
    ` : null};

    strong, b{
        font-weight: bold;
    }

    em, i{
        font-style: italic;
    }

    @media screen and (min-width: 768px) {
        padding: ${props => props.paddingM ? props.paddingM : "72px 0"};
        ${props => props.minHeightM ? css`min-height: ${props.minHeightM};` : null}
        ${props => props.noPaddingTop && css`
            padding-top: 0;
        `}
    }

    @media screen and (min-width: 1200px) {
        border-left-width: 4px;
        border-right-width: 4px;
        ${props => props.minHeightL ? css`min-height: ${props.minHeightL};` : null}

        ${props => props.borderTop ? css`
            border-top-width: 4px;
        ` : null};

        ${props => props.borderBottom ? css`
            border-bottom-width: 4px;
        ` : null};

        padding: ${props => props.paddingL ? props.paddingL : "105px 0"};

        ${props => props.noPaddingTop && css`
            padding-top: 0;
        `}
    }

    @media screen and (min-width: 1800px) {
        padding: ${props => props.paddingXL ? props.paddingXL : "120px 0"};
        ${props => props.minHeightXL ? css`min-height: ${props.minHeightXL};` : null}
        ${props => props.noPaddingTop && css`
            padding-top: 0;
        `}
    }

    @media screen and (min-width: 2200px) {
        padding: ${props => props.paddingXXL ? props.paddingXXL : "145px 0"};
        ${props => props.minHeightXXL ? css`min-height: ${props.minHeightXXL};` : null}
        ${props => props.noPaddingTop && css`
            padding-top: 0;
        `}
    }
`;

const Section = ({children, paddingS, paddingM, paddingL, paddingXL, paddingXXL, minHeightS, minHeightM, minHeightL, minHeightXL, minHeightXXL, bg, fg, center, bgImage, minHeight, maxHeight, hr, theme, border, borderTop, borderBottom, flexGrow, noFlex, sectionSibling, inline}) => {
    return (
        <SectionEl
            as={inline ? "span" : "div"}
            paddingS={paddingS}
            paddingM={paddingM}
            paddingL={paddingL}
            paddingXL={paddingXL}
            paddingXXL={paddingXXL}
            noPaddingTop={sectionSibling}
            minHeightS={minHeightS}
            minHeightM={minHeightM}
            minHeightL={minHeightL}
            minHeightXL={minHeightXL}
            minHeightXXL={minHeightXXL}
            bg={bg}
            fg={fg}
            center={center}
            bgImage={bgImage}
            minHeight={minHeight}
            maxHeight={maxHeight}
            hr={hr}
            theme={theme}
            border={border !== false ? true : false}
            borderTop={borderTop === true ? true : false}
            borderBottom={borderBottom === true ? true : false}
            flexGrow={flexGrow}
            noFlex={noFlex}
        >
            {children}
        </SectionEl>
    );
};

export default Section;
