import React from 'react';
import styled from "@emotion/styled";

const SVG = styled.svg`
    width: 100%;
    height: auto;
    position: relative;
    z-index: 50;
    display: block;
`;

const Logo = (props) => {
    return (
        <SVG width="761" height="202" viewBox="0 0 761.89 202.4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M171.17,34.1l-33.09,98.52H110.59L156.13,1h30.08l45.5,131.62H204.26L171.17,34.1"/>
            <polygon points="26.79 1 1.22 1 1.22 132.62 80.75 132.62 80.75 109.12 26.79 109.12 26.79 1"/>
            <polygon
                points="218.46 24.51 256.44 24.51 256.44 132.62 282.58 132.62 282.58 24.51 320.56 24.51 320.56 1 218.46 1 218.46 24.51"/>
            <polygon
                points="362.04 77.34 408.49 77.34 408.49 54.4 362.04 54.4 362.04 24.32 413.19 24.32 413.19 1 336.47 1 336.47 132.62 415.07 132.62 415.07 109.31 362.04 109.31 362.04 77.34"/>
            <polygon points="456.55 1 430.98 1 430.98 132.62 510.52 132.62 510.52 109.12 456.55 109.12 456.55 1"/>
            <rect x="526.43" y="1" width="25.57" height="131.62"/>
            <polygon
                points="593.48 77.34 639.93 77.34 639.93 54.4 593.48 54.4 593.48 24.32 644.63 24.32 644.63 1 567.91 1 567.91 132.62 646.51 132.62 646.51 109.31 593.48 109.31 593.48 77.34"/>
            <path
                d="M711,77.72q18.79-.94,29-10.44t10.25-26.79q0-18.06-11.29-28.77T706.68,1h-45.5V132.62H686V85.8l42.12,46.82h32.53ZM686,58V24.17h20.31q9.21,0,14.19,4.42t5,12.32q0,7.89-5,12.5T706.32,58Z"/>
            <path d="M80.75,53.48V1h24.84V29L97.12,53.5Z"/>
            <path
                d="M339.71,160.28c.25,4.28.5,15.08.5,19,0,3.63,0,15.78-.64,21v.41h19c9.33,0,19.19-2.9,19.19-11.19,0-5.19-3.75-9-10.78-11.12,5.22-1.4,8.69-5,8.69-9,0-6.77-7.77-9.31-15.08-9.49h-21Zm14.66,4.15c7.92.06,10.82,1.67,10.82,6a4.51,4.51,0,0,1-1.07,3c-1.51,1.83-4.46,2.77-8.23,2.53h-.19l-2.22,5.76h2.22c4.36-.12,8.16,1.26,10.12,3.64a5.85,5.85,0,0,1,1.35,3.73c0,4.5-4,6.83-11.8,6.83-1.32,0-3.46,0-4.79,0-.23-6-.33-14.15-.33-16.6,0-2.84.19-9.16.33-14.57A21.83,21.83,0,0,1,354.37,164.43Z"/>
            <path
                d="M421.59,159.9h-9.24l.38,10.3c.17,5.09.35,10.32.71,15.4h0c-.6-1.59-1.16-2.41-4-6.5,0-.14-9.61-14.91-12.94-19.11l-.1-.09H385.66v.38c.34,4.28.48,15.08.48,19,0,3.63,0,15.78-.51,21l-.1.41h9.56v-.41c-.55-10-.55-20-.55-29.78h0a14,14,0,0,0,2.26,4.43c.13.27,16.59,24.22,17.38,25.59l.09.17h7.65v-.41c-.58-5.24-.63-17.39-.63-21,0-3.94.26-14.74.51-19l.12-.38Z"/>
            <path
                d="M432.59,160.28c.37,4.28.51,15.08.51,19,0,3.63,0,15.78-.56,21v.41H443.9v-.41c-.39-3.77-.59-11-.63-16.45l2.15.11H447c15,0,22.22-4,22.22-12.28,0-9.48-10.2-11.65-18.71-11.83h-18Zm14,4.55c5.33-.1,9,1,10.8,3.21a5.63,5.63,0,0,1,1.26,3.69c0,5.9-6.65,7.19-12.2,7.19l-2.26-.13-.93-.1v-5.18c0-2.57,0-5.62.12-8.44A24.63,24.63,0,0,1,446.59,164.83Z"/>
            <path
                d="M490.9,160.28c.37,4.28.57,15.08.57,19,0,3.63,0,15.78-.63,21v.41h11.38l-.07-.41c-.36-3.77-.57-11-.57-16.45l2,.11h1.65c14.94,0,22.28-4,22.28-12.28,0-9.48-10.26-11.65-18.8-11.83H490.9Zm14,4.55c5.33-.1,9.05,1,10.83,3.21a5.61,5.61,0,0,1,1.24,3.69c0,5.9-6.64,7.19-12.13,7.19l-2.25-.13s-.59,0-.94-.1c-.07-1.36-.07-3.17-.07-5.18,0-2.6,0-5.62.11-8.44A21.58,21.58,0,0,1,504.9,164.83Z"/>
            <path
                d="M542.76,159.9h-.17l-.08.2c-2.11,5.81-15.75,35.57-17.9,40.13l-.18.5h9.68l.08-.22c.85-2.8,2-6,3.24-9.33l8.08-.11h6.68s1.08.06,1.4.06c1.72,4.39,3,7.7,3.62,9.38l.11.17h11.27l-18.12-40.83h-7.68Zm2.77,12c.9,1.94,3.24,7.37,5.89,13.85h-.92l-5,.06-6-.08c2.17-5.13,4.29-9.81,6-13.8Z"/>
            <path
                d="M573.12,160.28c.33,4.28.49,15.08.49,19,0,3.63,0,15.78-.62,21v.41h11.43l0-.41c-.6-5.27-.64-17.39-.64-21,0-2.84.09-9.16.37-14.28.87-.06,2.19-.19,3.08-.19,5.29-.1,9,1,10.78,3.21a5.49,5.49,0,0,1,1.22,3.69c0,4.71-4.4,7.1-8.51,7.32a12.73,12.73,0,0,1-2.39-.13l-.8-.13.44.75c4.06,5.92,10.13,16.17,12.64,21l.07.17h12.21l-.25-.52c-.5-.68-8.45-12.4-11.83-17.4l-.3-.3c6.26-1.88,9.29-5.38,9.29-10.75,0-9.41-10.2-11.65-18.81-11.83H573.09Z"/>
            <path
                d="M629,159.9H618.12l.06.38c.28,4.28.5,15.08.5,19,0,3.61,0,15.75-.56,21v.41h11.29l-.06-.41c-.43-5.27-.57-17.41-.57-21,0-3.94.27-14.74.57-19v-.38Z"/>
            <path
                d="M639.66,160.28c.33,4.28.53,15.08.53,19,0,3.63,0,15.78-.58,21l-.09.41h19c9.3,0,19.2-2.9,19.2-11.19,0-5.19-3.76-9-10.84-11.12,5.21-1.4,8.73-5,8.73-9,0-6.77-7.79-9.31-15-9.49h-21Zm14.65,4.15h0c8,.06,10.88,1.67,10.88,6a4.46,4.46,0,0,1-1.09,3c-1.44,1.83-4.51,2.77-8.25,2.53h-.18l-2.2,5.76h2.2c4.38-.12,8.14,1.26,10.1,3.64a5.58,5.58,0,0,1,1.4,3.73c0,4.5-4,6.83-11.86,6.83-1.26,0-3.39,0-4.72,0-.33-6-.33-14.15-.33-16.6,0-2.84.11-9.16.33-14.57a21,21,0,0,1,3.72-.27Z"/>
            <path
                d="M705.7,159.9h-7.65l0,.18c-2.28,6.18-16.69,37.51-17.89,40.1l-.22.55h9.7l.06-.24c.81-2.8,1.94-5.94,3.2-9.37l8-.13,6.71.11H709c1.76,4.38,3.06,7.72,3.63,9.37l.1.24H724l-18.12-40.83Zm-4.7,12c.91,1.89,3.24,7.33,5.89,13.82-.41,0-.9.05-.9.05l-5,.06s-5.21-.11-6-.11c2.14-5.2,4.22-9.85,6-13.81Z"/>
            <path
                d="M727.24,170.46c0,7.17,7.18,10.06,13.55,12.7,5,2,10.14,4,10.14,7.47a3.55,3.55,0,0,1-.79,2.52c-1.19,1.4-3.6,2-7.43,1.78a34.69,34.69,0,0,1-12.88-3.56l-1.88-.91v8.89l1.28.33.75.13a49.31,49.31,0,0,0,11.52,1.67c12,0,19.15-4.78,19.15-12.69,0-7.43-7.39-10.32-13.92-12.92-4.81-1.94-9.79-3.88-9.79-7.06,0-.92,0-3.6,6.22-3.6,4.57,0,9,1.86,11.55,2.83l2.31,1v-8.92l-2.29-.42a52.48,52.48,0,0,0-10.22-1.24C734.14,158.42,727.24,163.31,727.24,170.46Z"/>
        </SVG>
    );
}

export default Logo;