import color from "./colors";

import {css} from "@emotion/core";

const h1 = css`
    font-family: Faktum, sans-serif;
    color: ${color("white")};
    line-height: 1;
    font-size: 40px;
    letter-spacing: -0.02em;
    font-weight: 600;
    text-transform: uppercase;

    @media screen and (min-width: 320px) {
        font-size: 45px;
    }

    @media screen and (min-width: 360px) {
        font-size: 55px;
    }

    @media screen and (min-width: 380px) {
        font-size: 60px;
    }

    @media screen and (min-width: 768px) {
        font-size: 90px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 110px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 130px;
    }

    @media screen and (min-width: 2200px) {
        font-size: 160px;
    }
`;

const h2 = css`
    font-family: Faktum, sans-serif;
    color: ${color("dark")};
    line-height: 1.05;
    font-size: 40px;
    letter-spacing: -0.02em;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;

    @media screen and (min-width: 768px) {
        font-size: 45px;
        margin-bottom: 18px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 55px;
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 65px;
        margin-bottom: 56px;
    }
`;

const h2Hero = css`
    font-family: Faktum, sans-serif;
    color: ${color("dark")};
    line-height: 1.05;
    font-size: 40px;
    letter-spacing: -0.02em;
    font-weight: 600;
    margin-bottom: 16px;
    display: block;

    @media screen and (min-width: 768px) {
        font-size: 45px;
        margin-bottom: 18px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 65px;
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 90px;
        margin-bottom: 56px;
    }

    @media screen and (min-width: 2200px) {
        font-size: 100px;
        margin-bottom: 66px;
    }
`;

const h3 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
        font-size: 28px;
        margin-bottom: 40px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 32px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 40px;
    }
`;

const h3Larger = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
        font-size: 28px;
        margin-bottom: 40px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 35px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 65px;
    }
`;

const h4 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 32px;
    font-weight: 600;

    @media screen and (min-width: 768px) {
        font-size: 28px;
        margin-bottom: 40px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 32px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 40px;
    }
`;

const h5 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.2;
    font-size: 22px;
    margin-bottom: 16px;
    font-weight: bold;

    @media screen and (min-width: 1200px) {
        font-size: 26px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 32px;
        margin-bottom: 24px;
    }
`;

const p1 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.4;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        font-size: 22px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 26px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 32px;
    }

    &:last-child:not(.lead):not(.quote){
        margin-bottom: 0;
    }
`;

const p2 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.4;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 22px;
        line-height: 1.3;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 28px;
    }

    &:last-child{
        margin-bottom: 0;
    }
`;

const label = css`
    font-size: 16px;
    line-height: 1.1;
    font-family: Faktum, sans-serif;
    text-transform: uppercase;

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;

const labelSmall = css`
    font-size: 14px;
    line-height: 1.1;
    font-family: Faktum, sans-serif;

    @media screen and (min-width: 1200px) {
        font-size: 16px;
    }
`;

const searchButton = css`
    font-size: 14px;
    line-height: 1.1;
    font-family: Faktum, sans-serif;
    text-transform: uppercase;
`;

const link = css`
    font-family: Neue Haas Unica, sans-serif;
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 1.4;
    font-size: 16px;

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }
`;

const menuLink = css`
    font-family: Neue Haas Unica, sans-serif;
    font-weight: bold;
    letter-spacing: 0.02em;
    line-height: 1.4;
    font-size: 15px;

    @media screen and (min-width: 1200px) {
        font-size: 17px;
    }
`;

const body1 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.4;
    font-size: 16px;
    margin-bottom: 16px;

    a{
        color: inherit;
        transition: color 0.2s ease;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        font-size: 17px;
        line-height: 1.35;
    }

    @media screen and (min-width: 1200px) {
        font-size: 20px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 22px;
    }

    &:last-child{
        margin-bottom: 0;
    }
`;

const body2 = css`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.4;
    font-size: 14px;
    margin-bottom: 16px;

    a{
        color: inherit;
        transition: color 0.2s ease;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 18px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 20px;
    }

    &:last-child{
        margin-bottom: 0;
    }
`;

const meta = css`
    font-family: Neue Haas Unica, sans-serif;
    font-size: 14px;
    line-height: 1.2;
`;

const tileMeta = css`
    font-size: 14px;
    line-height: 1.1;
    font-family: Faktum, sans-serif;
    text-transform: uppercase;

    @media screen and (min-width: 400px) {
        font-size: 16px;
    }

    @media screen and (min-width: 768px) {
        font-size: 14px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }
`;

const ul = css`
    ${body1};
    list-style-type: square;
    padding-left: 1.2em;
`;

const ol = css`
    ${body1};
    list-style-type: none;
    font-weight: 600;
    counter-reset: item;

    li{
        font-weight: normal;
        counter-increment: item;
        text-indent: -1.3em;
        padding-left: 1.3em;

        &::before{
            font-weight: 600;
            content: counter(item) ".";
            width: 1.3em;
            display: inline-block;
            text-indent: 0;
        }
    }
`;

const embed = css`
    div[data-oembed]{
        padding-top: 56.25%;
        position: relative;

        iframe{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            height: 100%;
        }
    }
`;

const homepageIntro = css`
    p{
        ${h3};
        text-transform: none;

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    ul{
        ${ul};
    }

    ol{
        ${ol};
    }

    h1{
        ${h1};
    }

    h2{
        ${h2};
    }

    h3{
        ${h3};
    }

    h4{
        ${h4};
    }

    h5{
        ${h5};
    }

    img{
        max-width: 100%;
    }

    ${embed};

    *:first-child, *:first-child *{
        margin-top: 0 !important;
    }
`;

const pageIntro = css`
    p{
        ${p1};
    }

    ul{
        ${ul};
    }

    ol{
        ${ol};
    }

    h1{
        ${h1};
    }

    h2{
        ${h2};
    }

    h3{
        ${h3};
    }

    h4{
        ${h4};
    }

    h5{
        ${h5};
    }

    img{
        max-width: 100%;
    }

    ${embed};

    *:first-child, *:first-child *{
        margin-top: 0 !important;
    }
`;

const opening = css`
    p{
        ${p2};
    }

    ul{
        ${ul};
    }

    ol{
        ${ol};
    }

    h1{
        ${h1};
    }

    h2{
        ${h2};
    }

    h3{
        ${h3};
    }

    h4{
        ${h4};
    }

    h5, h6{
        ${h5};
    }

    img{
        max-width: 100%;
    }

    ${embed};
    
    *:first-child, *:first-child *{
        margin-top: 0 !important;
    }
`;

const standard = css`
    p{
        ${body1};
    }

    ul{
        ${ul};
    }

    ol{
        ${ol};
    }

    h1{
        ${h1};
    }

    h2{
        ${h2};
    }

    h3{
        ${h3};
    }

    h4{
        ${h4};
    }

    h5, h6{
        ${h5};
    }

    img{
        max-width: 100%;
    }

    ${embed};

    *:first-child, *:first-child *{
        margin-top: 0 !important;
    }
`;

const small = css`
    p{
        ${body2};
    }

    ul{
        ${ul};
    }

    ol{
        ${ol};
    }

    h1{
        ${h1};
    }

    h2{
        ${h2};
    }

    h3{
        ${h3};
    }

    h4{
        ${h4};
    }

    h5, h6{
        ${h5};
    }

    img{
        max-width: 100%;
    }

    ${embed};

    *:first-child, *:first-child *{
        margin-top: 0 !important;
    }
`;

const RTESpacing = css`
    h1, h2, h3, h4, h5, h6{
        margin-top: 64px;
    }

    h1 + h2, h1 + h3, h1 + h4, h1 + h5, h1 + h6, h2 + h3, h2 + h4, h2 + h5, h2 + h6, h3 + h4, h3 + h5, h3 + h6, h4 + h5, h4 + h6, h5 + h6{
        margin-top: 0;
    }
`;

const typography = {
    h1,
    h2,
    h2Hero,
    h3,
    h3Larger,
    h4,
    h5,
    p1,
    p2,
    label,
    labelSmall,
    searchButton,
    link,
    menuLink,
    body1,
    body2,
    meta,
    tileMeta,
    ul,
    ol,
    embed,
    homepageIntro,
    pageIntro,
    opening,
    standard,
    small,
    RTESpacing
}

export default typography;