export default function color(color) {
    switch(color) {
        case "white":
            return "#FFFFFF";
        case "grey":
            return "#DADADA";
        case "dark grey":
            return "#868686";
        case "meta grey":
            return "#949AAA";
        case "extra light":
            return "#E9F2FF";
        case "background blue":
            return "#EEF6FF";
        case "blue grey":
            return "#667395";
        case "blue":
            return "#0450C2";
        case "dark blue":
            return "#002891";
        case "dark":
            return "#001428";
        case "base blue":
            return "#A9C4E7";
        case "lite blue":
            return "#F8FBFF";
        case "insights blue":
            return "#F4F5FF";
        case "border":
            return "#f2f4f7";
        case "header blue":
            return "#A9C4E7"
        case "base peach":
            return "#FFD0C0";
        case "green":
            return "#DAF6F4";
        default:
            return color;
    }
}

export function hexToRGB(hex, alpha) {
    hex   = hex.replace('#', '');
    var r = parseInt(hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2), 16);
    var g = parseInt(hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4), 16);
    var b = parseInt(hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6), 16);
    if ( alpha ) {
       return `rgba(${r}, ${g}, ${g}, ${alpha})`;
    }
    else {
       return `rgb(${r}, ${g}, ${b})`;
    }
 }