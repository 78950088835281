import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import { Link } from 'gatsby';

const buttonStyle = css`
    ${typography.label};
    line-height: 1.3;
    margin-top: 31px;
    font-weight: 600;
    text-decoration: none;
    color: ${color("dark")};
    border: 3px solid ${color("dark")};
    padding: 22px 52px;
    display: inline-block;
    transition: color 0.2s ease, border-color 0.2s ease;
    text-transform: uppercase;

    &:hover{
        color: ${color("dark blue")};
        border-color: ${color("dark blue")};
    }

    @media screen and (min-width: 768px) {
        margin-top: 52px;
    }

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 40px;
        padding: 24px 52px;
    }

    @media screen and (min-width: 1800px) {
        margin-top: 48px;
    }
`;

const ButtonA = styled.a`
    ${buttonStyle};
`;

const ButtonLink = styled(Link)`
    ${buttonStyle};
`;

const ButtonSpan = styled.span`
    ${buttonStyle};
`;

const Button = ({text, url, internal, className}) => {
    return (
        <>
            {url ? (
                internal ? 
                    (
                        <ButtonLink to={url} className={className}>
                            {text}
                        </ButtonLink>
                    ) : (
                        <ButtonA href={url} target="_blank" rel="noopener noreferrer" className={className}>
                            {text}
                        </ButtonA>
                    )
            ) : (
                <ButtonSpan className={className}>
                    {text}
                </ButtonSpan>
            )}
        </>
    );
};

export default Button;
