import React from "react";
import { useStaticQuery, graphql } from "gatsby"
const SocialLinks = () => {
    const { prismicOptions } = useStaticQuery(
        graphql`
            {
                prismicOptions {
                    data {
                        linkedin {
                            url
                        }
                        medium {
                            url
                        }
                        twitter {
                            url
                        }
                        youtube {
                            url
                        }
                    }
                }
            }
        `
    )
    const {linkedin, medium, twitter, youtube} = prismicOptions.data;
    return (
        <>
            <li>
                <a href={twitter.url} target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
            <li>
                <a href={linkedin.url} target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </li>
            <li>
                <a href={medium.url} target="_blank" rel="noopener noreferrer">Medium</a>
            </li>
            <li>
                <a href={youtube.url} target="_blank" rel="noopener noreferrer">YouTube</a>
            </li>
        </>
    );
};

export default SocialLinks;
