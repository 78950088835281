import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Button from "../Elements/Button";
import Container from "../Context/Container";
import Section from "../Context/Section";

const Title = styled.h2`
    ${typography.h2};
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: 24px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 16px;
    }
`;
const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
`;

const CTA = ({title, content, buttonText, buttonUrl, buttonInternal, theme}) => {
    const titleExists = title.trim();
    const contentExists = content && content !== "<p></p>";
    return titleExists || contentExists || buttonText ? (
        <Section bg="white" fg="dark" center={true} theme={theme}>
            <Container type="narrow">
                <Title>
                    {title}
                </Title>
                <Standard dangerouslySetInnerHTML={{ __html: content }} />
                <Button text={buttonText} url={buttonUrl} internal={buttonInternal} />
            </Container>
        </Section>
    ) : null;
};

export default CTA;
