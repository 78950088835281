import React from "react";
import { Link } from 'gatsby';

const CustomLink = ({children, url, internal, className, onClick, draggable}) => {
    return (
        <>
            {url ? (
                internal ? 
                    (
                        <Link to={url} className={className} onClick={onClick} draggable={draggable}>
                            {children}
                        </Link>
                    ) : (
                        <a href={url} target="_blank" rel="noopener noreferrer" className={className} onClick={onClick} draggable={draggable}>
                            {children}
                        </a>
                    )
                ) : (
                    <span className={className} onClick={onClick}>
                        {children}
                    </span>
                )
            }
        </>
    );
};

export default CustomLink;
