import React, {useState} from "react";
import 'intersection-observer';
import { InView } from 'react-intersection-observer';

import styled from "@emotion/styled";

const Wrapper = styled(InView)`
    position: relative;
    opacity: ${props => props.isHidden ? "0" : "1"};
    transform: translateY(${props => props.isHidden ? "10vh" : "0"});
    transition: opacity ${props => props.time} cubic-bezier(0.205, 0.520, 0.135, 1), transform ${props => props.time} cubic-bezier(0.205, 0.520, 0.135, 1);
    transition-delay: ${props => `${props.delay}`};
`;

const FadeUp = ({children, delay, time, threshold, className, as}) => {
    const [isHidden, setIsHidden] = useState(true);

    return (
        <Wrapper
            isHidden={isHidden}
            delay={delay ? delay : "0"}
            time={time ? time : "1.4s"}
            className={className}
            threshold={threshold ?? 0.1}
            as={as ?? "div"}
            onChange={inView => {
                if(isHidden) setIsHidden(!inView);
        }}>
            {children}
        </Wrapper>
    );
};

export default FadeUp;
