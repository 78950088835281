import React, {useRef, useEffect} from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Search from "../Context/Search";
import SocialLinks from "./SocialLinks";

const Wrapper = styled.div`
    background: ${color("white")};
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 34px;
    border-bottom: 3px solid ${props => props.theme === "dark" || props.menuOpen ? color("border") : color("white")};
    transition: border-color 0.2s ease;

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }
`;

const Navigation = styled.div`
    padding: 0 14px;
    text-align: right;
    display: none;

    ul{
        height: 100%;
    }

    li{
        display: inline-block;
        height: 100%;
    }

    a{
        ${typography.menuLink};
        color: ${color("dark")};
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 10px;
        text-decoration: none;
        font-weight: normal;

        &:hover{
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 1200px) {
        display: block;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 22px;
    }
`;

const MenuBar = ({theme, menuOpen, setHeight}) => {
    const topBar = useRef(null);

    useEffect(() => {
        updateHeight();

        window.addEventListener("resize", updateHeight);

        return () => {
            window.removeEventListener("resize", updateHeight);
        }
    }, []);

    const updateHeight = () => {
        const newHeight = topBar.current.offsetHeight;
        setHeight(newHeight);
    }

    return (
        <Container type="header">
            <Wrapper theme={theme} menuOpen={menuOpen} ref={topBar}>
                <Search />
                <Navigation>
                    <ul>
                        <SocialLinks />
                    </ul>
                </Navigation>
            </Wrapper>
        </Container>
    );
};

export default MenuBar;
