import React, {forwardRef} from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import { Link } from 'gatsby';
import typography from "../../styles/typography";
import Container from "../Context/Container";
import CloseSVG from "../SVGs/CloseSvg";
import TopBar from "../Context/TopBar";
import SocialLinks from "./SocialLinks";
import { useStaticQuery, graphql } from "gatsby"

const MenuWrapper = styled.div`
    position: fixed;
    background: ${color("white")};
    display: inline-block;
    z-index: 900;
    height: ${props => props.height};
    width: 100%;
    overflow: hidden;
    transform: ${props => props.isOpen ? "translateY(0)" : "translateY(-100%)"};
    transition: transform 0.6s ${props => props.isOpen ? "cubic-bezier(0.720, 0.140, 0.465, 1)" : ''};
    border: 3px solid ${color("border")};

    @media screen and (min-width: 1200px) {
        height: 100vh;
        border-width: 4px;
        top: 0;
        transform: ${props => props.isOpen ? "translateY(0)" : "translateY(-100%)"} ${props => props.spacingTop && !props.isOpen ? `translateY(- ${props.spacingTop} px)` : null};
    }
`;

const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: ${props => `${props.topBarHeight}px`};;
    left: 0;
    bottom: 0;
    width: 100%;
`;

const Close = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
    right: 0;
    cursor: hand;
    cursor: pointer;
    ${typography.menuLink};
    color: ${color("dark")};
    text-decoration: none;
    transition: color 0.2s ease;
    font-weight: 600;
    text-transform: none;

    svg{
        fill: ${color("dark")};
    }

    span{
        display: none;
        margin-right: 16px;
    }

    &:hover{
        text-decoration: underline;
    }

    @media screen and (min-width: 1200px) {
        span{
            display: inline-block;
        }
    }

    @media screen and (min-width: 1200px) {
        padding: 32px;
    }
`;

const Navigation = styled.ul`
    padding: 0 13px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    li{
        display: block;
    }

    a{
        ${typography.h3};
        color: ${color("dark")};
        display: inline-block;
        padding: 0 30px;
        text-decoration: none;
        transition: color 0.2s ease;
        text-transform: none;
        font-weight: 600;
        margin: 0;
        width: auto;

        &:hover, &.active{
            text-decoration: underline;
        }
    }

    @media screen and (min-height: 480px) {
        a{
            ${typography.h2};
            margin: 0;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    };

    @media screen and (min-height: 540px) {
        a{
            margin: 0;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    };

    @media screen and (min-height: 650px) {
        a{
            margin: 0;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @media screen and (min-width: 768px) {
        text-align: center;

        a{
            ${typography.h2};
            margin: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        a{
            margin: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    @media screen and (min-width: 1800px) {
        a{
            margin: 0;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
`;

const SocialNavigation = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top: 3px solid ${color("border")};

    li{
        display: inline-block;
    }

    a{
        ${typography.menuLink};
        color: ${color("dark")};
        display: inline-block;
        flex-direction: row;
        align-items: center;
        padding: 20px 10px;
        text-decoration: none;
        text-decoration: underline;
        font-weight: normal;
    }

    @media screen and (min-width: 1200px) {
        display: none;
    }
`;

const Menu = forwardRef(({isOpen, handleClose, spacingTop, theme, updateTopBarHeight, topBarHeight, height}, ref) => {
    const { prismicOptions } = useStaticQuery(
        graphql`
            {
                prismicOptions {
                    data {
                        main_menu {
                            name
                            link {
                                url
                            }
                        }
                    }
                }
            }
        `
    );
    return (
        <MenuWrapper isOpen={isOpen} spacingTop={spacingTop} height={height} ref={ref}>
            <TopBar theme={theme} menuOpen={isOpen} setHeight={updateTopBarHeight} />
            <Container type="full">
                <ContainerInner topBarHeight={spacingTop}>
                    <Close onClick={handleClose} spacingTop={spacingTop}>
                        <span>Close</span><CloseSVG />
                    </Close>
                    <Navigation>
                        {prismicOptions.data.main_menu.map((item, i) => {
                            return item.link.url ? (
                                <li key={i}><Link to={item.link.url} activeClassName="active" onClick={handleClose}>{item.name}</Link></li>
                            ) : null;
                        })}
                    </Navigation>
                    <SocialNavigation>
                        <SocialLinks />
                    </SocialNavigation>
                </ContainerInner>
            </Container>
        </MenuWrapper>
    );
});

export default Menu;
