import React, {useState, useEffect, useRef, createRef} from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import TopBar from "../Context/TopBar";
import MenuBar from "../Context/MenuBar";
import Menu from "../Context/Menu";

const HeaderContainer = styled.div`
    width: 100%;
    position: ${props => props.displayStatic ? "static" : "fixed"};
    border: 3px solid ${props => props.theme === "dark" || props.menuOpen ? color("border") : color("white")};
    box-sizing: border-box;
    z-index: 300;
    top:-200px;

    ${
        props => {
            const { headerOpen,topBarHeight, theme, showSearch, displayStatic } = props
            const barHeight = showSearch ? 200 : 200-topBarHeight;
            return css`
                transition: border-color 0.2s ease, transform ${ (headerOpen) ? '0.6s cubic-bezier(0.785, 0.090, 0.270, 0.930)' : '.4s'}, background-color 0.4s;
                transform: translateY(${ (headerOpen && !displayStatic) ? barHeight : 0}px);
                background-color: ${(theme === "dark") ? '#ffffff' : 'rgba(255,255,255,0)' };
            `
        }
    }

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }
`;

let track = {
    last:0,
    lastUp:0,
    turnPoint:0,
    direction:1
}

const Header = ({theme, preventNav, hideNav}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [themeOveride, setThemeOveride] = useState(theme);
    const [headerOpen, setHeaderOpen] = useState(true);
    const [showSearch, setShowSearch] = useState(true);
    const [topBarHeight, setTopBarHeight] = useState(100);
    const [menuHeight, setMenuHeight] = useState("100vh");

    const menu = useRef();

    const updateTopBarHeight = (newHeight) => {
        setTopBarHeight(newHeight);
    }

    const handleMenuOpen = () => {
        setMenuOpen(true);

        if(window.innerWidth <= 1200) disableBodyScroll(menu.current);
    }

    const handleMenuClose = () => {
        setMenuOpen(false);

        enableBodyScroll(menu.current);
    }

    const scrollFn = () => {
        const y = window.pageYOffset;
        let down = track.last < y;
        const dist = y - track.last;

        if(down && track.direction < 0) track.turnPoint = track.last;
        if(!down && track.direction > 0)  track.turnPoint = track.last;

        const totalDist = y - track.turnPoint;

        track.direction = Math.sign(dist);
        track.last = y;

        if(preventNav) down = true;
        if(Math.abs(totalDist) >= 50 && y > 50) setHeaderOpen( !down );

        if( y < 50 ) {
            setThemeOveride(theme);
            setShowSearch(true);
            setHeaderOpen(true);
        }else {
            setThemeOveride('dark');
            setShowSearch(false);
        }
    }

    const updateMaxHeight = () => {
        setMenuHeight(window.innerHeight + "px");
    }

    const checkScrollPosChooseMenuState = () => {
        const y = window.pageYOffset;

        if(y > 50){
            setThemeOveride('dark');
            setShowSearch(false);
        }
    }

    useEffect(() =>{
        window.addEventListener('scroll', scrollFn);
        return () => { window.removeEventListener('scroll', scrollFn); }
    },[headerOpen, showSearch, themeOveride, preventNav]);

    useEffect(() =>{
        updateMaxHeight();
        checkScrollPosChooseMenuState();
        window.addEventListener('resize', updateMaxHeight);

        return () => {
            window.removeEventListener('resize', updateMaxHeight);
        }
    },[]);

    return (
        <>
            <HeaderContainer theme={themeOveride} topBarHeight={hideNav ? 0 : topBarHeight} showSearch={ showSearch } headerOpen={hideNav ? true : headerOpen} menuOpen={menuOpen} displayStatic={hideNav}>
                {hideNav !== true && <TopBar theme={themeOveride} menuOpen={menuOpen} setHeight={updateTopBarHeight} />}
                <MenuBar theme={themeOveride} handleMenuOpen={handleMenuOpen} hideNav={hideNav} />
            </HeaderContainer>
            {hideNav !== true && <Menu isOpen={menuOpen} handleClose={handleMenuClose} spacingTop={topBarHeight} theme={themeOveride} updateTopBarHeight={updateTopBarHeight} height={menuHeight} ref={menu} />}
        </>
    );
};

export default Header;
