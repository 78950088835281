import React, {useState, useRef} from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";

const SearchContainer = styled.div`
    position: relative;
    flex-grow: 1;
    padding: 5px 16px;
    max-width: 100%;

    @media screen and (min-width: 768px) {
        padding: 5px 24px;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 32px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;

const Label = styled.label`
    display: none;
`;

const Input = styled.input`
    ${typography.label};
    display: inline-block;
    letter-spacing: 0.04em;
    text-transform: none;
    appearance: none;
    background: white;
    border: none;
    padding: 2px 16px 2px 0;
    box-sizing: border-box;
    background: none;
    z-index: 500;
    color: ${color("dark")};
    flex-grow: 1;
    width: 50px;
    max-width: 345px;
    border-radius: 0;
    
    &:focus{
        outline: none;
    }

    @media screen and (min-width: 1200px) {
        width: 345px;
        flex-grow: 0;
    }
`;

const PlaceholderText = styled.div`
    ${typography.label};
    color: ${color("dark grey")};
    display: inline;
    position: absolute;
    padding: 0;
    opacity: ${props => props.focused || props.hasContent ? "0" : "1"};
    transition: opacity 0.2s ease;
    text-transform: none;
`;

const SearchButton = styled.button`
    ${typography.searchButton};
    display: inline-block;
    padding: 6px 5px 5px;
    background: ${color("lite blue")};
    color: ${color("dark")};
    border: none;
    cursor: hand;
    cursor: pointer;
    opacity: ${props => props.focused || props.hasContent ? "1" : "0"};
    visibility: ${props => props.focused || props.hasContent ? "visible" : "hidden"};
    transition: opacity 0.2s ease, visibility 0.2s ease, background-color 0.2s ease;
    font-weight: bold;
    margin: 0;
    
    &:hover, &:focus {
        background: ${color("border")};
    }
    &:focus{
        outline: none;
    }

    @media screen and (min-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

const Search = ({theme}) => {
    const [focused, setFocused] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const searchButton = useRef(null);

    const handleFocus = () => {
        setFocused(true)
    }

    const handleBlur = (e) => {
        const {relatedTarget} = e;
        if(relatedTarget !== searchButton.current) setFocused(false);
    }

    return (
        <SearchContainer theme={theme}>
            <Form
                action="/search"
                method="GET"
                onBlur={handleBlur}
            >
                <Label
                    htmlFor={"search"}
                >
                    Search
                </Label>
                <Input
                    id={"search"}
                    type={"text"}
                    name={"query"}
                    required
                    onFocus={handleFocus}
                    onChange={e => {
                        if (e.currentTarget.value) {
                            setHasContent(true);
                        } else {
                            setHasContent(false);
                        }
                    }}
                />
                <PlaceholderText focused={focused} hasContent={hasContent}>Search</PlaceholderText>
                <SearchButton focused={focused} hasContent={hasContent} ref={searchButton}>Search</SearchButton>
            </Form>
        </SearchContainer>
    );
};

export default Search;
